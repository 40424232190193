import { Link } from "gatsby";
import React from "react";

const CDIntroduction = () => {
  return (
    <section className="cd-intro">
      <h1 className="cd-intro__title  leading-[50px]">
        Harness Continuous Delivery & GitOps
      </h1>
      <div className="cd-intro__description">
        <p className="cd-intro__description__para mb-6">
          可視性と制御性を備えたソフトウェアの迅速なデリバリー。
        </p>
        <p className="cd-intro__description__para mb-[30px]">
          宣言型GitOpsとパワフルで使いやすいパイプラインにより、スクリプトや手作業によるデプロイを排除します。AI/MLによるカナリア／ブルーグリーン自動デプロイ、高度な検証、インテリジェントなロールバックにより、新機能をより迅速に提供できるようになります。
        </p>
        <Link to="/inquiry/" className="cd-intro__btn">
          お問い合わせ
        </Link>
      </div>
    </section>
  );
};

export default CDIntroduction;
