import React from "react";
import GridCard from "../gridCard/GridCard";

const GridCards = ({ title, description, cards, bg }) => {
  return (
    <section className="grid-cards" style={{ backgroundColor: bg }}>
      <div className="grid-cards__head">
        <h2 className="grid-cards__head__title">{title}</h2>
        {description && (
          <p className="grid-cards__head__description">{description}</p>
        )}
      </div>
      <div className="grid-cards__container">
        {cards.map((card) => (
          <GridCard card={card} key={card.id} />
        ))}
      </div>
    </section>
  );
};

export default GridCards;
