import React from "react";

const GridCard = ({ card }) => {
  return (
    <div className="grid-card">
      {card?.icon && <div className="grid-card__icon">{card.icon}</div>}
      <h3 className="grid-card__title">{card.title}</h3>
      <p className="grid-card__body">{card.paragraph}</p>
    </div>
  );
};

export default GridCard;
