import React from "react";
import Container from "@layout/container";
import CDIntroduction from "@components/cd/Introduction/CDIntroduction";
import CDBenefits from "@components/cd/Benefits/CDBenefits";
import Meta from "@components/seo/Meta";
import { graphql } from "gatsby";

const CD = (props) => {
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Harness Continuous Delivery & GitOps｜Harness正規販売代理店 DXable`,
    description: `Hanress Continuous Delivery & GitOpsでは、可視性と制御性を備えたソフトウェアの迅速なデリバリーが可能になります。`,
    keywords: `Harness,harness,Harness.io,harness.io,ハーネス,DevOps,CD,Continuous Delivery,継続的デリバリー,Harness代理店`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <CDIntroduction />
        <CDBenefits />
      </Container>
    </>
  );
};

export const cdPageData = graphql`
  query fetchCdPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default CD;
