import React from "react";
import { benefits, gitOpsServices, gitOpsBanner } from "../contents";
import GridCards from "@components/common/gridCards/GridCards";
import CasualBanner from "@components/common/casualBanner/CasualBanner";

const CDBenefits = () => {
  return (
    <section className="cd-benefits">
      <GridCards title={"CD & GitOpsの概要"} cards={benefits} bg="#fff" />
      <div className="hn-container mb-[120px]">
        <CasualBanner
          textContents={gitOpsBanner.textContents}
          img={gitOpsBanner.img}
        />
      </div>
      <GridCards
        title={"GitOps-as-a-Serviceの主な機能"}
        cards={gitOpsServices}
        bg="#fff"
      />
    </section>
  );
};

export default CDBenefits;
