import React from "react";

const CasualBanner = ({ textContents, img }) => {
  return (
    <div className="casual-banner">
      <div className="casual-banner__text-contents">{textContents}</div>
      <div className="casual-banner__img">{img}</div>
    </div>
  );
};

export default CasualBanner;
