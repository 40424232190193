import React from "react";
import icon1 from "../../images/cd/Icon1.svg";
import icon2 from "../../images/cd/Icon2.svg";
import icon3 from "../../images/cd/Icon3.svg";
import icon4 from "../../images/cd/Icon4.svg";
import icon5 from "../../images/cd/Icon5.svg";
import icon6 from "../../images/cd/Icon6.svg";
import icon7 from "../../images/cd/Icon7.svg";
import icon8 from "../../images/cd/Icon8.svg";
import icon9 from "../../images/cd/Icon9.svg";
import icon10 from "../../images/cd/Icon10.svg";
import icon11 from "../../images/cd/Icon11.svg";
import icon12 from "../../images/cd/Icon12.svg";
import icon13 from "../../images/cd/Icon13.svg";
import icon14 from "../../images/cd/Icon14.svg";
import icon15 from "../../images/cd/Icon15.svg";
import icon16 from "../../images/cd/Icon16.svg";
import gitOpsBannerImage from "../../images/cd/gitOps.svg";

export const benefits = [
  {
    id: 1,
    title: "自動化されたカナリア／ブルーグリーンデプロイメント",
    paragraph:
      "これらのデプロイ戦略は、より速く、より安全で、より効率的です。また、KubernetesのエキスパートがHarnessを使わずに手動でセットアップする必要があります。Harnessがセットアップを行うので、お客様はメリットを享受するだけです。",
    isSVG: true,
    icon: <img src={icon1} loading="lazy" width="80px" alt="Icon1" />,
  },
  {
    id: 2,
    title: "あらゆるデプロイメント段階での可視化と制御",
    paragraph:
      "手動でのスクリプト作成は不要。再現可能なデプロイメントにより、下位レベルおよび本番環境へのアーティファクトの自動デプロイ、検証、ロールバックが可能です。",
    isSVG: true,
    icon: <img src={icon2} loading="lazy" width="80px" alt="Icon2" />,
  },
  {
    id: 3,
    title: "デプロイメント検証およびロールバックの自動化",
    paragraph:
      "Harnessは、観測ソリューションのデータとログに機械学習を適用することで、不適切なデプロイメントを自動的に検出します。さらに、自動的に以前のバージョンにロールバックするオプションも提供されます。",
    isSVG: true,
    icon: <img src={icon3} loading="lazy" width="80px" alt="Icon3" />,
  },
  {
    id: 4,
    title: "開発者フレンドリーなPipeline-As-Codeエクスペリエンス",
    paragraph:
      "パイプラインは、コードの管理と同じ方法で管理できます。Gitに保存し、コードベースと一緒に配置し、YAMLで記述し（オートコンプリートとスキーマ検証を備えた組み込みのYAMLエディターを使用）、プルリクエストを使って変更を扱います。",
    isSVG: true,
    icon: <img src={icon4} loading="lazy" width="80px" alt="Icon4" />,
  },
  {
    id: 5,
    title: "統合された承認と通知フロー",
    paragraph:
      "パイプラインをチケット管理システムに統合することで、手動ステップを減らし、パイプラインの進行を自動的に承認します。また、パイプラインの実行中に発生する全ての重要なアクティビティーが通知されます。",
    isSVG: true,
    icon: <img src={icon5} loading="lazy" width="80px" alt="Icon5" />,
  },
  {
    id: 6,
    title: "インフラストラクチャーの自動プロビジョニング",
    paragraph:
      "一般的なIaaSとコンピュート統合を使用してインフラストラクチャーを簡単にプロビジョニングし、デプロイメントが行われる前に環境が設定され準備されていることを確認します。",
    isSVG: true,
    icon: <img src={icon6} loading="lazy" width="80px" alt="Icon6" />,
  },
  {
    id: 7,
    title: "柔軟なパラメトリゼーションとテンプレートライブラリー",
    paragraph:
      "ベストプラクティスを実施し、異なるチーム間でパイプラインを再利用できます。パイプラインのテンプレートを作成することで、異なるチームが簡単に導入でき、設定にかかる時間を短縮し、開発に時間をかけることができます。",
    isSVG: true,
    icon: <img src={icon7} loading="lazy" width="80px" alt="Icon7" />,
  },
  {
    id: 8,
    title: "ガバナンスと監査証跡",
    paragraph:
      "監査やコンプライアンスは退屈です。Harnessは、監査の可視化と監査証跡により、次の監査を完了させ、コンプライアンスを維持するお手伝いをします。",
    isSVG: true,
    icon: <img src={icon8} loading="lazy" width="80px" alt="Icon8" />,
  },
  {
    id: 9,
    title: "エンタープライズクラスのセキュリティー",
    paragraph:
      "きめ細かいカスタムRBAC、広範なシークレットマネージャーのサポート、ファーストクラスのLDAP統合など、Harnessはエンタープライズクラスのセキュリティーを提供します。セキュリティーはHarness CDの最優先事項です。",
    isSVG: true,
    icon: <img src={icon9} loading="lazy" width="80px" alt="Icon9" />,
  },
  {
    id: 10,
    title: "Continuous Verification",
    paragraph:
      "Continuous Verificationは、教師なし機械学習と既存のロギングおよびAPMソリューションとの統合により、リリーステストプロセスの自動化を支援します。エンジニアリングチームは、デプロイメントで次の段階に進む前にリスクを検証し、アクションを実行することができます。",
    isSVG: true,
    icon: <img src={icon10} loading="lazy" width="80px" alt="Icon10" />,
  },
];

export const gitOpsBanner = {
  textContents: (
    <>
      <h3 className="hn-h3 hn-blog-title leading-[32px] mb-6 text-hn-blog-title">
        Harness GitOps-as-a-Service
      </h3>
      <p className="hn-p2 leading-6">
        Harness
        GitOps-as-a-Serviceは、完全に管理されたGitOps体験を提供します。チームは迅速に作業を開始でき、お客様は独自のArgo
        CD環境の管理にかかるオーバーヘッドに時間を費やすことがなくなります。
      </p>
    </>
  ),
  img: (
    <img
      src={gitOpsBannerImage}
      width="562px"
      loading="lazy"
      alt="Harness GitOps Banner"
    />
  ),
};

export const gitOpsServices = [
  {
    id: 1,
    title: "可視化とレポーティング",
    paragraph:
      "アプリケーション、デプロイメント、デプロイメントベロシティーの詳細なデータにより、GitOpsデプロイメントを分析することができます。特定の環境とサービスレベルチャートにドリルダウンして、デプロイメント戦略の健全性を評価できます。",
    isSVG: true,
    icon: <img src={icon11} loading="lazy" width="80px" alt="Icon11" />,
  },
  {
    id: 2,
    title: "ガバナンス",
    paragraph:
      "RBAC、監査証跡、統合 SSO などのエンタープライズガバナンスコントロールにより、デプロイメントを安全に保護できます。",
    isSVG: true,
    icon: <img src={icon12} loading="lazy" width="80px" alt="Icon12" />,
  },
  {
    id: 3,
    title: "検証およびロールバック",
    paragraph:
      "観測可能なソリューションからのデータとログに機械学習を適用することで、不正なデプロイメントを自動的に検出。さらに、自動的に以前のバージョンにロールバックするオプションも提供されます。",
    isSVG: true,
    icon: <img src={icon13} loading="lazy" width="80px" alt="Icon13" />,
  },
  {
    id: 4,
    title: "集中管理",
    paragraph:
      "GitOpsインスタンス、デプロイメント、ロールバックを単一の集中型ダッシュボードで管理できます。",
    isSVG: true,
    icon: <img src={icon14} loading="lazy" width="80px" alt="Icon14" />,
  },
  {
    id: 5,
    title: "プルリクエストパイプラインのオーケストレーション",
    paragraph:
      "プルリクエスト（PR）パイプラインは、複数のサービスや環境に変更を伝達し、開発者が各デプロイメントを個別に管理する必要はなくなります。",
    isSVG: true,
    icon: <img src={icon15} loading="lazy" width="80px" alt="Icon15" />,
  },
  {
    id: 6,
    title: "柔軟なセットアップ",
    paragraph:
      "ホスティングされたフルマネージドGitOps-as-a-Serviceか、セルフマネージメントのArgo CDインスタンス接続を選択できます。",
    isSVG: true,
    icon: <img src={icon16} loading="lazy" width="80px" alt="Icon16" />,
  },
];
